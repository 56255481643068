import Model from './Model'

import Field from '../types/Field'
import BooleanField from '../types/BooleanField'

export default class Brand extends Model {
    endpoint = 'brands'

    fields = [
      new Field('id', 'Código').disabled(),
      new Field('name_es', 'Nombre (español)'),
      new Field('name_en', 'Nombre (inglés)'),
      new BooleanField('status', 'Activo', 1),
    ]

    clone = () => Brand;
}
